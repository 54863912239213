html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  margin: 0;
  font-size: 16px;
}
h1,
h2,
h3,
h4,
p,
blockquote,
figure,
ol,
ul {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4 {
  line-height: 1.2em;
}
//li {
//  display: block;
//}
h1,
h2,
h3,
h4 {
  font-size: inherit;
  font-weight: normal;
}
a {
  text-decoration: none;
  color: inherit;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  transition: .3s;
}

img {
  max-width: 100%;
  width: auto;
  height: auto;
  border: 0;
}

#logo {
  z-index: 322;

  text-align: center;
  @include breakpoint($desktop) {
    text-align: center;
//    @include grid-column(4);
    position: relative;
    margin-bottom: 20px;
  }
  @include breakpoint($max-desktop) {
    text-align: left;
    margin-bottom: 0;

    @include grid-column(5);
  }
  > a {
    img {
      max-width: 150px;
      @include breakpoint($desktop) {
        max-width: 200px;
      }
      @include breakpoint($max-desktop) {
        max-width: 260px;
      }
      width: 100%;
      margin-top: 5px;
    }

    @include breakpoint($desktop) {
      //position: absolute;
      //top: 5px;
      //left: 20px;
      //right: 0;
    }
  }
}

#block-block-2 {
  position: relative;
  z-index: 3;
}

#nav {
  @include breakpoint($mobile) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  @include breakpoint($desktop) {
    position: relative;
  }
}

.menu-dropper {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  @include breakpoint($desktop) {
    display: none;
  }
}

#block-block-6 { // top right banner

  max-width:400px;
  padding:20px;
  margin:0 auto;
  @include breakpoint($desktop) {
    max-width:500px;
    padding:0;
  }
  @include breakpoint($max-desktop) {
    max-width:100%;
    padding-left:400px;
  }

  p {
    padding-top: 10px;
    text-align:center;
    @include breakpoint($desktop) {
      padding: 20px;
    }
    @include breakpoint($max-desktop) {
      padding: 30px 0 0 0;
    }
  }
  img {
    margin: 0 auto;

  }

}
#block-block-8, #block-block-9 {
  @include breakpoint($desktop) {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 344;
  }
  p {
    margin: 0;
    border-bottom: 2px solid $color-secondary;
    @include breakpoint($desktop) {
      border-bottom: none;
    }
  }
  a {
    margin-bottom: 0;
  }
  a {
    color: $color-secondary;

    @include breakpoint($mobile) {
      border: none;
      display: block;
      background: #F3EFEF !important;
      padding: 10px 20px;
      font-weight: normal;
      width: 100%;
      max-width: inherit;
    }
  }
  a:first-child {
    @include breakpoint($desktop) {
      background-image: url(../images/button-med.png), linear-gradient(to bottom, #fefcf8 0%, #faf2e1 100%);
    }
  }
  a:last-child {
    @include breakpoint($desktop) {
      background-image: url(../images/button-med.png), linear-gradient(to bottom, #f3f0f4 0%, #cbbfd0 100%);
      margin-left: 15px;
    }
  }
}

#block-block-9 a:last-child {
  background-image: url(../images/button-med.png), linear-gradient(to bottom, #ebf5fe 0%, #aed6f9 100%);
}

#strapline {
  text-align: center;
  @include breakpoint($desktop) {
    position: absolute;
    bottom: -36px;
    text-align: left;
  }
  @include grid-row();
  height: 36px;
  padding-top: 14px;
  background: #e1e3e4 url(../images/strapline-bg.jpg);
}
.strapline-holder {
  @include grid-column(15);
  @include breakpoint($desktop) {
    @include grid-column(5, $offset: 1);
  }
  img {
    vertical-align: top;
  }
}

.node-type-landing-page #strapline {
  background: none;
}

#topheader {
  .row {
    //text-align: right;
    padding: 0 30px
  }
}
.topheader-content {
  padding-top: 4px;
  position: relative;

  p, ul ,li {
    position: relative;
    display: inline-block;
    color: white;
    font-size: 12px;
    padding: 0;
  }
  p {
    vertical-align: text-top;
    padding-top: 14px;
    @include breakpoint($desktop) {
      padding-top: 5px;
    }
  }

  a {
    color: #ffffff !important;
    text-decoration: none;
  }

  p:first-child {
    a {
      @include breakpoint($mobile) {
        color: transparent !important;
        width: 20px;
        display: inline-block;
      }

    }

    @include breakpoint($mobile) {
      //display: none;
    }

    &:first-child a:before {
      position: absolute;
      left: -17px;
      top: 14px;
      @include breakpoint($desktop) {
        top: 4px;
      }
      content: url(../images/address-pin.png);
    }
  }

  p:nth-child(2) {
    margin-left: 38px;
    @include breakpoint($large-desktop) {
      margin-left: 58px;
    }
    position: relative;
    padding-left: 20px;

    @include breakpoint($mobile) {
      margin-left: 0;
      padding-top: 13px;
      font-size: 14px;
    }

    &:before {
      position: absolute;
      left: 0;
      top: 14px;
      content: url(../images/phone-pin.png);
      @include breakpoint($desktop) {
        top: 4px;
      }
    }
  }

  p:nth-child(3) {
    margin-left: 38px;
    @include breakpoint($large-desktop) {
      margin-left: 58px;
    }

    position: relative;
    padding-left: 20px;

    @include breakpoint($mobile) {
      margin-left: 10px;
      padding-top: 13px;
      font-size: 14px;
    }

    &:before {
      position: absolute;
      left: 0;
      top: 13px;
      font: normal normal normal 16px/1 FontAwesome;
      content: "\f0e0";
      @include breakpoint($desktop) {
        top: 2px;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    @include breakpoint($mobile) {
      display: none;
    }
  }
  li {
    padding: 5px 0 0 25px;
    @include breakpoint($large-desktop) {
      padding: 5px 0 0 45px;
    }
    margin: 0;
  }

  .icon {
    border:0;
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
  }

  .icon.twitter:after {
    content: url(../images/icon-twitter.png);
  }

  .icon.facebook:after {
    content: url(../images/icon-facebook.png);
  }

  .icon.google:after {
    content: url(../images/icon-google.png);
  }
}

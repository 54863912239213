#footer-holder {
  background: #58595b;
  padding: 0;
  color: white;

  h2 {
    margin-bottom: 30px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    &:after {
      content: "";
      display: block;
      width: 25px;
      height: 2px;
      background: white;
      position: absolute;
      bottom: -10px;
    }
  }

  p, .sp-text {
    color: white;
    font-size: 15px;
  }

  a {
    color: white;
    text-decoration: none;
  }
  
  .row {
    display: table;
  }
}

#block-block-2, #block-block-4 {
  @include grid-column(15);

  @include breakpoint($desktop) {
    @include grid-column(5);
  }
}

.icon.star {
  margin-top: 20px;
}

#block-block-2, #block-block-4 {
  p.icon {
    padding-left: 30px;
    position: relative;
    margin-bottom: 10px;
  }
  .icon:before {
    position: absolute;
    left: 0;
  }

  p.noicon {
    margin-bottom: 4px;
  }
}

.icon.address:before {
  font: normal normal normal 22px/1 FontAwesome;
  content: "\f041";
}
.icon.telephone:before {
  font: normal normal normal 25px/1 FontAwesome;
  content: "\f095";
  top: 2px;
}
.icon.email:before {
  font: normal normal normal 22px/1 FontAwesome;
  content: "\f0e0";
}
.icon.star:before {
  font: normal normal normal 22px/1 FontAwesome;
  content: "\f005";
}

#block-block-3 {
  position: relative;
  @include grid-column(15);
  @include breakpoint($desktop) {
    @include grid-column(5);
  }
  p {
    font-size: 15px;
    color: white;
    margin-bottom: 4px;
  }
  strong {
    display: inline-block;
    width: 50%;
    font-weight: normal;
  }
}

#block-block-2 {
  @include breakpoint($desktop) {
    padding-right: 80px;
  }
}
#block-block-3 {
  @include breakpoint($desktop) {
    padding-left: 40px;
    padding-right: 40px;
  }
}
#block-block-4 {
  @include breakpoint($desktop) {
    padding-left: 80px;
  }
}



@include breakpoint($large-desktop) {
  #block-block-3:before,
  #block-block-3:after {
    position: absolute;
    top: 0;
    //content: url(../images/footer-divider.png);
  }
  #block-block-3:before {
    left: 0;
  }
  #block-block-3:after {
    right: 0;
  }
}

#block-sp-twitter-sp-twitter-block {
  @include grid-column(15);
  @include breakpoint($desktop) {
    @include grid-column(13, $offset: 1);
    margin-top: 30px;
  }
  @include breakpoint($large-desktop) {
    @include grid-column(4, $offset: 1);
    margin-top: 0;
  }
  .owl-item {
    text-align: left;
  }
}
.sp-twitter {
  width: 280px;
}

#footer-holder .row > div {
  @include breakpoint($mobile) {
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid white;
  }

  &:last-child {
    border: none;
    padding-bottom: 0;
  }
}

#subfooter {
  background: #3d3b38;
  padding: 5px 0;
  color: white;
  //border-top: 1px solid #eae0db;
  font-size: 12px;
  text-align: center;

  .row {
    position: relative;
    padding: 20px;
  }

  p {
    color: white;
    font-size: 12px;
    margin-bottom: 0;
    position: relative;
  }

  img {
    float: left;
  }

  a {
    color: white;
    text-decoration: none;
    white-space:nowrap;
  }

  .extralinks a:after {
    content: "-";
    padding: 0 14px;
  }

  .extralinks a:last-child:after {
    content: "";
    padding: 0;
  }
}

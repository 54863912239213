$top-header: #BB90C1;
$header-bg: #ffffff ;

#topheader {
  background: $top-header;
  height: 50px;
  @include breakpoint($desktop) {
    height: 34px;
  }
  position: relative;
}

#header {
  @include clearfix;
  background: $header-bg;
  padding: 16px 0 0;
  position: relative;
  height: auto;
  @include breakpoint($max-desktop) {
   height: 200px;
  }
  .row {
    padding: 0 20px;
  }
}

.ds-homebanner .owl-controls {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;

  @include breakpoint($mobile) {
    bottom: -40px;
  }
}

.node-type-basic-page .ds-body {
  padding: 0 10px;
}

#banner {
  background: white;
  p {
    margin: 0;
  }
}

#banner .row {
  text-align: center;
  padding: 20px 10px;
  @include breakpoint($desktop) {
    padding: 50px 10px;
  }
}

div.contextual-links-wrapper {
  z-index: 99;
  display: inherit !important;
}

#block-system-main-menu .contextual-links-wrapper ul.contextual-links {
  display: none;
}
.contextual-links-region:hover a.contextual-links-trigger {
  display: block;
}

.tabs {
  height: 38px;
}

ul.tabs.primary {
  @include breakpoint(max-width 1024px) {
    top: 0;
  }
  position: fixed;
  z-index: 498;
  margin: 0;
  padding: 0;
  border-bottom: none;
  @include clearfix;

  li {
    font-size: 18px;
    margin-right: -5px;
    a {
      font-family: "Open Sans","Segoe UI","Helvetica",sans-serif;
      background: #DEDEDE;
      border: none;
      font-weight: 300;
      padding: 5px 18px;
      color: black;
      margin-right: 0;
      display: inline-block;
      //border-bottom: 2px solid white;
    }
  }
  li.active a {
    background: white;
  }
  li:hover a {
    background: #0074BD;
    color: white;
  }
}

.tabs li {
  padding: 0;
}

.field-collection-view {
  margin: 0;
  padding: 0;
}

.field-collection-container {
  border: none;
  margin: 0;

}

.block-system-navigation {
  padding-top: 40px;
  clear: both;
}

#admin-menu {
  font-size: 12px;
}

.page-user .block-block-6 {
  display: none;
}

ul.pager {
  padding: 0;
  margin: 0;
}

.main-content .item-list-pager ul, #main-content .item-list-pager li:first-child {
  margin-left: 0;
}

.main-content .item-list-pager {
  //text-align: center;
  @include grid-column(4);
  font-size: 16px;
  margin-top: 10px;
  padding-bottom: 40px;

  li {
    text-transform: uppercase;
  }
  a {
    text-decoration: none !important;
  }

  .pager > li {
    //display: inline-block;
    margin: 0;
  }

  .previous a {
    padding-right: 10px;
  }

  .next a {
    padding-left: 10px;
  }
}

.profile {
  @include grid-row();
  padding: 20px;
}

.page-user #main-content {
  @include grid-row();
  padding: 70px 20px;

  form {
    background: lightgrey;
    border: 1px solid #555;
    padding: 30px;
    div {
      padding-bottom: 30px;
    }
    margin: 0 auto;

    @include breakpoint($large-desktop) {
      width: 50%;
    }

    label {
      display: block;
      font-size: 24px;
      font-weight: 400;
      @include breakpoint($desktop) {
        display: inline-block;
        width: 200px;
        padding-right: 20px;
        padding-top: 13px;
        vertical-align: top;
      }
    }

    .form-required {
      color: inherit;
    }
    .form-radios > div {
      display: inline-block;
    }
    ::placeholder {
      text-transform: uppercase;
      font-weight: 700;
    }

    input[type='text'],
    input[type='email'],
    input[type='password'],
    textarea {
      width: 100%;
      @include breakpoint($desktop) {
        width: calc(100% - 205px);
      }
      padding: 7px;
      display: inline-block;
      font-size: 24px;
      border-width: 2px;
      border-style: solid;
      border-color: grey;
    }

    select {
      width: 100%;
      font-size: 24px;
      border-width: 2px;
      border-style: solid;
      border-color: grey;
      @include breakpoint($desktop) {
        display: inline-block;
        width: calc(100% - 205px);
      }
      padding: 7px;
    }

    textarea {
      display: block;
    }

    .form-actions {
      padding: 0;
      text-align: center;
    }

    .form-actions input {
      border: none;
      background: #555;
      color: white;
      padding: 12px 60px;
      font-size: 24px;
      text-transform: uppercase;
      margin: 0;
      font-weight: 700;
      cursor: pointer;
    }
  }

}

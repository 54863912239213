.header-rhs {
  @include breakpoint($mobile) {
    display: none;
    margin-top: 20px;
  }
  @include breakpoint($desktop) {
    display: block !important;
    padding-bottom: 20px;
  }
}

#block-menu-block-1 {
  z-index: 321;
  position: relative;
  @include breakpoint($desktop) {
    text-align: center;
  }
  @include breakpoint($max-desktop) {
    text-align: right;
    padding-top: 30px;
  }

  @include breakpoint($mobile) {
    background: #f3efef;
    padding-bottom: 10px;
    border-bottom: 2px solid $color-secondary;
    padding-top: 10px;
  }

  li {
    list-style: none;
  }

  .active-trail {
    font-weight: 400;
  }
  .active {
    font-weight: 700;
  }

  .nolink {
    cursor: pointer;
    @include breakpoint($mobile) {
      opacity: 0.5;
    }

  }

  .hide-children ul {
    display: none !important;
  }

  > ul {
    position: relative;
  }

  /* VERY TOP LEVEL ******************************************/
  > ul > li {
    //position: relative;
    text-transform: uppercase;
    font-size: 18px;
    color: $color-secondary;
    display: block;

    @include breakpoint($desktop) {
      padding: 0 12px;
      display: inline-block;
      margin-right: -.25em;
      background: none;
    }

    > a.active-trail, > span.active-trail {
      @include breakpoint($desktop) {
        border-bottom-width: 2px;
        border-bottom-style: solid;
      }
    }

    a, span {
      @include breakpoint($mobile) {
        display: block;
        padding: 10px 20px;
      }
      color: $color-secondary;
      padding-bottom: 4px;
      text-decoration: none;
    }

    &:last-child {
      padding-right: 0;
    }
  }
  /* VERY TOP LEVEL END ***************************************/

  > ul > li.short-menu {
    position: relative;
  }

  > ul > li.long-menu > ul > li {
    break-inside: avoid;
  }

  > ul > li.long-menu > ul {
    @include breakpoint($desktop) {
      column-count: 3;
      column-gap: 40px;
      width: 100%;
    }
    margin: 0 auto;
  }

  /* SECOND LEVEL *********************************************/
  > ul > li > ul {
    background: white;
    display: none;
    font-weight: 400;

    // The very bottom of the first level menu
    @include breakpoint($mobile) {
      background: #f3efef;
      display: block;
      //border-top: 1px solid $color-secondary;
      //border-bottom: 1px solid $color-secondary;
    }

    @include breakpoint($desktop) {
      position: absolute;
      padding: 30px 20px;
      top: 22px;
      left: 0;
      width: 300px;
      text-align: left;
      border-bottom: 3px solid $color-secondary;
    }

    > li {
      padding: 10px 0 10px 20px;
      @include breakpoint($desktop) {
        padding: 10px 0;
      }
      > a, span {
        @include breakpoint($mobile) {
          font-size: 16px;
        }
        display: block;
        border-bottom: 1px solid lightgrey;
      }
    }
  }

  // Make the last drop down item float to the left so it doesn't go off the page
  > ul > li:last-child > ul {
    left: auto;
    right: 0;
  }
  /* SECOND LEVEL END *****************************************/

  /* THIRD LEVEL **********************************************/
  > ul > li > ul > li > ul {
    padding: 0;
    @include breakpoint($desktop) {
      padding: 20px 0 0;
    }
  }

  > ul > li > ul > li > ul li {
    font-size: 14px;
  }

  /* THIRD LEVEL END ******************************************/
}

.screenreadable {
  border: 0px none;
  font: 0px/0 a;
  text-shadow: none;
  color: transparent;
}

.hideme {
  display: none;
}

body {
  font-family: $font-main;
  line-height: 24px;
  color: #464646;
  background: white;
}

textarea, input {
  font-family: $font-main;
  font-size: 15px;
}

.node-type-landing-page.front .menu-dropper {
  display: none;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  margin: 0 auto 20px;
  clear: both;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

h1 {
  font-family: $font-heading;
  font-size: 26px;

  @include breakpoint($desktop) {
    font-size: 53px;
  }
}

h2 {
  font-size: 22px;
  @include breakpoint($desktop) {
    font-size: 40px;
  }
  padding-bottom: 10px;
  font-weight: 300;
}

.ds-title h2 {
  font-family: $font-heading;
}

h3 {
  font-size: 16px;

  @include breakpoint($desktop) {
    font-size: 30px;
  }
  text-transform: uppercase;
  padding-bottom: 10px;
}

p {
  margin-bottom: 22px;
  color: $color-primary;
  font-weight: 400;
  line-height: 1.3em;
  font-size: 16px;
  @include breakpoint($desktop) {
    font-size: 22px;
  }
}
p:last-child {
  margin-bottom: 0;
}

.entity-paragraphs-item p a {
  text-decoration: underline;
}

.main-title {
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  padding: 0 0 20px;
  color: $color-primary;
}

#main-content ul li {
  list-style-type: square;
  font-size: 16px;

  @include breakpoint($desktop) {
    font-size: 22px;
  }
  line-height: 1.5em;
  padding-bottom: 10px;
  margin-left: 0;
}

#main-content ol li {
  font-size: 16px;

  @include breakpoint($desktop) {
    font-size: 22px;
  }
  line-height: 1.5em;
  padding-bottom: 10px;
  margin-left: 0;
}


.field-name-body ul li {
  list-style-position: outside;
}

a {
  color: $color-primary;
  text-decoration: underline;
}

b, strong {
  font-weight: 700;
}

#page-holder {
  .item-list ul, ol {
    margin: 0;
  }

  .item-list ul li, .item-list ol li {
    margin: 0;
  }
}

#main-content {
  ul, ol, li {
    margin-left: 20px;
  }

  ul, ol {
    margin-bottom: 22px;
  }

  blockquote {
    display: inline-block;
    padding: 0 30px;
    font-style: italic;
    position: relative;
    &:before {
      content: open-quote;
      position: absolute;
      left: 4px;
      top: 0;
      font-size: 40px;
      color: $color-primary;
    }
    &:after {
      content: close-quote;
      position: absolute;
      right: 12px;
      bottom: 0;
      font-size: 40px;
      color: $color-primary;
    }
  }
}

.row {
  @include grid-row();
}

.field-name-title h1 {
  font-size: 25px;
  font-weight: 700;
  color: $color-primary;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  padding: 0 0 20px;
}

.ds-title .field-name-title h1 {
  padding: 20px 0;
  text-align: center;
  @include breakpoint($desktop) {
    padding: 50px 0 36px;
  }
}

.md-arrow-left, .md-arrow-right {
  background: url(../images/bg_direction_nav.png) no-repeat;
}
.md-arrow-right {
  background-position: right top;
}

.back-button {
  display: inline-block;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-decoration: none !important;
  border-bottom: 1px solid $color-primary;
  padding-bottom: 0;
  margin-bottom: 20px;
  margin-left: 10px;
  @include breakpoint($desktop) {
    margin-left: 0;
  }

}

.button {
  padding: 5px 30px 5px 15px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  background: white;
  color: $color-secondary;
  //width: 100%;
  display: block;
  //border-image: linear-gradient(to right, #817c74 0%,#3d3b38 100%);
}


.field-name-field-treatment-links a {
  max-width: 300px;
}

a.inline-button {
  display: inline-block;
  min-width: 220px;
  color: $color-secondary;
}

table {
  text-align: left;
  margin: 0 auto 20px;
  border: 10px solid #ddd;
  background: white;
  background-size: 14px 100%;

  td,th {
    color: #222;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  td, th {
    border-right: 3px solid #ddd;
    padding-left: 15px;
    padding-right: 15px;

  }
  td:last-child, th:last-child {
    border-right: none;
  }

  tr {
    border-bottom: 3px solid #ddd;
  }
}


#main-content .ipricing {
  margin-left: 0;
  border: 2px solid #c4c3c9;
  display: inline-block;
  background: white;
  li {
    margin-left: 0;
    padding: 6px 80px 6px 20px;
    list-style: none !important;
  }

  li:first-child {
    background-image: url(../images/button-med.png), url(../images/strapline-bg.jpg);
    background-position: right bottom, left top;
    background-repeat: no-repeat, repeat;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
  }

  &.clicked li:first-child {
    background-image: url(../images/button-med-down.png), url(../images/strapline-bg.jpg);
  }


  li:nth-child(1n + 2) {
    display: none;
  }
}


.pricing-header {
  background: $color-tertiary;
  td {
    position: relative;
    color: black;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  td:first-child:after {
    position: absolute;
    top: 11px;
    content: "";
    margin-left: 10px;
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 8px 0 8px;
    border-color: black transparent transparent transparent;
  }
}

.fixed-pricing-table, .pricing-table {
  width: 100%;
  border: none;
  background: #f4f4f4;
  @include breakpoint($desktop) {
    width: 80%;
  }

  .pricing-header {
    background: $color-tertiary;
    cursor: pointer;
    td {
      position: relative;
      color: black;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }

    td:first-child:after {
      position: absolute;
      top: 11px;
      content: "";
      margin-left: 10px;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 8px 0 8px;
      border-color: black transparent transparent transparent;
    }
  }

  .pricing-header.clicked {
    td {
      color: white;
    }

    td:first-child:after {
      border-width: 0 8px 12px 8px;
      border-color: transparent transparent white transparent;
    }
  }

  td {
    font-size: 14px;
  }

  tbody {
    border: none;
  }

  td {
    border-right: none;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  th, tr {
    border-bottom: 1px solid $color-tertiary;
  }
  tr:last-child {
    border-bottom: none;
  }
}

.fixed-pricing-table {
  max-width: 800px;
  width: 100%;

  td {
    @include breakpoint($desktop) {
      font-size: 19px;
      padding: 12px 30px;
    }
  }
}


.fixed-pricing-table th, .fixed-pricing-table tr {
  border: none;
}


.fixed-pricing-table .pricing-header {
  background: #a0cd80;

  td {
    color: white;
  }

  td:first-child::after {
    border: none;
  }
}

.fixed-pricing-table tr:nth-child(even) {
  background: white;
}

a.button-special {

  @include breakpoint($mobile) {
    display: block;
    margin: 10px 0;
  }

  white-space:nowrap;
  background: $color-tertiary;
  text-transform: uppercase;
  color: white !important;
  text-decoration: none;
  padding: 14px 20px;
  position: relative;

  &:after {
    content: "\f138";
    font-family: "FontAwesome";
    font-size: 22px;
    padding-left: 10px;
    position: absolute;
    right: 10px;
    top: 15px;
  }
}

.fa-bars {
  font-size: 30px;
}

.fwbg {
  background-size: cover;
}

.large-button {
  text-transform: uppercase;
  padding: 20px 32px;
  border-width: 2px;
  border-style: solid;

  &:hover {
    text-decoration: none;
  }
}

.row {
  padding: 30px 20px;

  @include breakpoint($desktop) {
    padding: 70px 20px;
  }
}


.notop .row {
  padding: 0 20px 30px;
  @include breakpoint($desktop) {
    padding: 0 20px 70px;
  }
}



.entity-paragraphs-item > .row > .ds-title > h2 {
  text-align: center;
  padding-bottom: 20px;

  @include breakpoint($desktop) {
    font-size: 53px;
    padding-bottom: 60px;
  }
}

.entity-paragraphs-item.narrow {
  p, .webform-client-form, ul {
    @include breakpoint($desktop) {
      max-width: 75%;
      margin: 0 auto 1em;
    }
  }

}

.readmore a {
  padding: 10px 20px;
  background: grey;
  color: white;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
}

img[style*=float] {
  @include breakpoint($mobile) {
    float: none !important;
    display: block;
    margin-bottom: 20px;
  }

}

#main-content .ds-text blockquote {
  padding-bottom: 20px;
}
